import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'
import { MyContextProvider } from '../src/context/MyContext';
import { HashRouter as Router } from 'react-router-dom';
import ErrorBoundary from "./ErrorBoundary"
createRoot(document.getElementById('root')).render(
  <Router>
    <MyContextProvider>
      <ErrorBoundary>
        <Provider store={store}>
          <App />
        </Provider>
      </ErrorBoundary>
    </MyContextProvider>
  </Router>
)
reportWebVitals()
