import React, { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorMessage: '' };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Error:', error);
    console.error('Error Info:', errorInfo);
    this.setState({ hasError: true, errorMessage: this.props.fallbackMessage });
  }

  render() {
    if (this.state.hasError) {
      return <div>{this.state.errorMessage}</div>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
