import { createContext, useState, useEffect } from "react";

const MyContext = createContext({});

export const MyContextProvider = ({ children }) => {
    const [userInformation, setUserInformation] = useState(null);
    useEffect(() => {
        const cachedData = localStorage.getItem('uData');
        if (cachedData) {
            setUserInformation(JSON.parse(cachedData));
        }
    }, []);
    useEffect(() => {
        if (userInformation) {
            localStorage.setItem('uData', JSON.stringify(userInformation));
        }
    }, [userInformation]);

    return (
        <>
            <MyContext.Provider value={{ userInformation, setUserInformation }}>
                {children}
            </MyContext.Provider>
        </>
    )
}

export default MyContext;