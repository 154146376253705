
import React, { useState, useEffect, Suspense, useContext } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import AuthService from './services/Auth.Services';
// import PrivateRoute from './services/PrivateRoute';
import { ToastContainer } from 'react-toastify';
import MyContext from "./context/MyContext";
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

function App() {
  const { userInformation } = useContext(MyContext);
  const userData = AuthService.getCurrentUser();
  const privateoRouter = AuthService.privateoRouter();
  const [currentUser, setCurrentUser] = useState(true);
  const dddd = privateoRouter ? 1 : 0
  useEffect(() => {
    if (Number(userData) === 0) {
      setCurrentUser(false);
    } else {
      setCurrentUser(true);
    }
  }, [userData, Number(dddd)]);
  return (
    // <BrowserRouter>
      <Suspense fallback={loading}>
        <ToastContainer />
        <Routes>
            <Route path="*" name="Home" element={<DefaultLayout />} />
            <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route exact path="/register" name="Register Page" element={<Register />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route exact path="*" name="Login Page" element={<Login />} />
          </Routes>
      </Suspense>
    // </BrowserRouter>
  )
}

export default App
